 .App {
  text-align: center;
}

@font-face {
  font-family: 'Black Bruno';
  src: url(fonts/BlackBruno.otf);
}


.COMINGSOON {
  font-family: "Black Bruno";
  color: black;
  font-size: 80px;
  left: 0;
  line-height: 110px;
  margin-top: -100px;
  position: absolute;
  text-align: center;
  top: 60%;
  width: 100%;
  
  
}